import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';

import { Icon } from '@/components';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import MainBg from '@/public/assets/pics/bg.png';
import { getBlogBreadcrumbs } from '@/utils/breadcrumbs';

interface IArticleHeroProps {
  title: string;
  description: string;
  publishedAt?: string;
  readTime: number;
}

export const ArticleHero: FC<IArticleHeroProps> = ({
  title,
  description,
  publishedAt,
  readTime,
}) => {
  const router = useRouter();

  const { t } = useTranslation();

  const date = new Date(publishedAt as string);

  const handleGoToBlogPage = () => {
    router.push('/blog', undefined, { locale: router.locale });
  };

  return (
    <div className="relative">
      <div className="absolute inset-0 -z-10 overflow-hidden bg-blue-50 xl:inset-x-4 xl:rounded-2xl">
        <Image
          src={MainBg}
          alt="Main Bg"
          className="object-cover"
          loading="lazy"
          fill
        />
      </div>
      <div className="relative px-5 pb-12 pt-6 md:px-[34px] lg:mx-auto lg:flex lg:w-full lg:max-w-[849px] lg:flex-col lg:px-0 lg:pb-16 xl:pb-[60px]">
        <div className="flex items-center justify-between">
          <Breadcrumbs breadcrumbs={getBlogBreadcrumbs()} />

          <p className="body3 text-gray-500">
            {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
          </p>
        </div>
        <div className="relative">
          <button
            type="button"
            onClick={handleGoToBlogPage}
            className="left-[-42px] top-[24px] hidden size-8 rounded-full xl:absolute xl:block"
          >
            <Icon
              name="shewronLeft"
              className="size-8 stroke-[2px] text-transparent"
            />
          </button>
          <h1 className="h3 md:h2 mt-[18px] tracking-[0px] md:tracking-[-0.64px]">
            {title}
          </h1>
        </div>
        <div className="flex items-center gap-2 py-8">
          {/* TODO: fix after you can get user info */}
          {/* <div className="relative h-8 w-8 rounded-full bg-red-700">img</div>
          <div className="flex flex-col justify-center">
            <p className="body3-bold tracking-[0.1px] text-blue-900">
              Alexander Gromov
            </p>
            <div className="flex items-center gap-1.5">
              <p className="body3 text-gray-800">CEO & Founder</p>
              <div className="h-1 w-1 rounded-full bg-gray-400" />
              <p className="body3 text-gray-500">{readTime} min read</p>
            </div>
          </div> */}
          <p className="body3 text-gray-500">
            {readTime}&nbsp;{t('blog.min_read')}
          </p>
        </div>
        <p className="font-inter text-[22px] leading-8 tracking-[0.2px] text-blue-900 lg:w-[847px]">
          {description}
        </p>
      </div>
    </div>
  );
};
