import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';

import { Container } from '@/components';
import { CURRENCY_IMAGES } from '@/store/storeSearch.consts';
import { cn } from '@/utils';

interface ITopDepositProps {
  className?: string;
}

export const TopDeposit: FC<ITopDepositProps> = ({ className }) => {
  const router = useRouter();

  return (
    <Container
      className={cn(
        'relative pb-12 pt-[50px] md:px-9 lg:pb-16 xl:px-10 xl:pb-[60px]',
        className,
      )}
    >
      <div className="flex items-center">
        <h4 className="whitespace-nowrap">Top-5</h4>
        <Image
          src={CURRENCY_IMAGES.eur!}
          alt="currency"
          className="ml-2 mr-1 h-6 w-6"
        />
        <h4 className="truncate tracking-[0.25px]">EUR deposit rates 🔥</h4>
      </div>
      <div className="mt-1 flex">
        <p className="body1 tracking-[0.1px] text-grey-800">
          The following banks offer the maximum EUR interest rates available in
          🇩🇪 <b className="font-semibold text-blue-900">Germany</b>
        </p>
      </div>
      <table className="mt-6 w-full md:mt-[7px]">
        <thead className="hidden md:table-header-group">
          <tr className="body3 border-b tracking-[0px] text-grey-600">
            <th className="py-4 text-left">Interest rate</th>
            <th className="text-left">Bank</th>
            <th className="text-left">Currency</th>
            <th className="text-left">Term</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr className="flex flex-wrap items-center gap-y-4 border-b py-4 first-of-type:border-t md:table-row">
            <td className="body2-bold md:h4 lg:h2-inter w-[110px] pl-8 text-blue-900 md:w-[116px] md:py-5 md:pl-0 lg:w-[196px] lg:py-[18px]">
              1.9%
            </td>
            <td className="-order-1 w-full pr-12 md:w-[294px] lg:w-[394px]">
              <div className="flex items-center gap-2">
                <div className="h-6 w-6 shrink-0 rounded-full bg-red-600 lg:h-10 lg:w-10" />
                <p className="body2-bold tracking-[0.2px] text-blue-900">
                  Addiko Bank AG
                </p>
              </div>
            </td>
            <td className="w-[78px] md:w-[109px] lg:w-[133px] xl:w-[256px]">
              <div className="flex items-center gap-2">
                <Image
                  src={CURRENCY_IMAGES.eur || ''}
                  alt="currency"
                  className="hidden h-5 w-5 lg:block"
                />
                <p className="body2">EUR</p>
              </div>
            </td>
            <td>
              <p className="body2">6 months</p>
            </td>
            <td className="absolute right-5 md:static md:ml-0 md:w-8">
              <button
                type="button"
                onClick={() => router.push('/')}
                className="h-8 w-8 rotate-180"
              >
                <ChevronLeftIcon className="pointer-events-none h-4 w-4 stroke-[2px] text-gray-400 md:h-8 md:w-8" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};
