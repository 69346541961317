export const encodeQueryData = (data: any, url: string) => {
  const existingParams = new Set<string>();

  // Extract existing parameters from the URL and add them to the set
  const existingQueryString = url.split('?')[1];
  if (existingQueryString) {
    existingQueryString.split('&').forEach((param) => {
      existingParams.add(param);
    });
  }

  const params = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      data.hasOwnProperty(key) &&
      data[key] !== undefined &&
      data[key] !== null
    ) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(data[key]);
      let param = `${encodedKey}=${encodedValue}`;
      param = param.endsWith('/') ? param.slice(0, -1) : param;
      param = param.endsWith('%2F') ? param.slice(0, -3) : param;

      // Check if the parameter already exists before adding it
      if (!existingParams.has(param)) {
        params.push(param);
        existingParams.add(param);
      }
    }
  }

  const queryString = params.join('&');
  const hasQueryString = url.includes('?');
  const finalSeparator = hasQueryString ? '&' : '?';
  const separatorToAdd = queryString ? finalSeparator : '';

  let finalUrl = `${url}${separatorToAdd}${queryString}`;

  // Ensure the URL does not end with a '/'
  finalUrl = finalUrl.endsWith('/') ? finalUrl.slice(0, -1) : finalUrl;
  finalUrl = finalUrl.endsWith('%2F') ? finalUrl.slice(0, -3) : finalUrl;

  return finalUrl;
};
