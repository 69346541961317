import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { FC } from 'react';
import React from 'react';

import { BankCard, Icon } from '@/components';
import useFavouriteDeposits from '@/hooks/useFavouriteDeposits';
import type { IBankDepositCard } from '@/types';

interface DepositSimilarDepositsProps {
  title: string;
  deposits: IBankDepositCard[];
}

export const OtherDeposits: FC<DepositSimilarDepositsProps> = ({
  title,
  deposits,
}) => {
  const { t } = useTranslation();

  const { handleFavourite, checkIsFavorite } = useFavouriteDeposits();

  return (
    <div className="relative before:absolute before:inset-0 before:z-[-1] before:bg-blue-900 lg:before:rounded-2xl xl:before:inset-x-4">
      <div className="px-5 py-12 md:px-[34px] lg:mx-auto lg:flex lg:w-full lg:max-w-[849px] lg:flex-col lg:px-0 lg:py-16">
        <h4 className="font-semibold leading-[30px] tracking-[0.25px] text-white">
          {title}
        </h4>
        <div className="mt-[30px] flex flex-col gap-[22px] md:mt-[32px] md:gap-[26px] lg:mt-[34px]">
          {deposits.map((bank) => (
            <BankCard
              key={bank.productIndex}
              bank={bank}
              isFavourite={checkIsFavorite(bank)}
              handleFavourite={() => handleFavourite(bank)}
              isBlogBankCard
            />
          ))}
          <Link
            href="/fixed-deposits"
            passHref
            type="button"
            className="btn-blue-states mx-auto flex content-center items-center gap-1 rounded-[32px] px-5 py-[18px] tracking-[0.2px] text-white disabled:bg-grey-300 md:h-[56px] md:w-[148px]"
          >
            <>
              <p className="body2-bold tracking-[0.2px] text-white">
                {t('global.show_more')}
              </p>
              <Icon
                name="arrowRightWhite"
                className="size-4 text-transparent"
              />
            </>
          </Link>
        </div>
      </div>
    </div>
  );
};
